.header{
    position: absolute;
    z-index: 999;
    width: 100%;
    left: 0;
    top: 0;
}

.navbar{
    padding: 0;

    &-brand{
        padding: 0;
        img{
            display: block;
            max-width: 90px;

            @include maxDevice(767px){
                max-width: 70px;
            }
        }
    }

    &-nav{
        font-size: 14px;

        a{
            font-family: 'IBM Plex Mono', monospace;
            border-bottom: 1px solid transparent;
            text-transform: uppercase;
            color: $dark !important;
            letter-spacing: 0.14rem;
            padding: 0 !important;
            margin-left: 60px;
            font-weight: 500;

            &.active, &:hover{
                border-color: #000;
            }

            &:hover{
                opacity: 0.8;   
            }
        }
    }

    &-toggler{
        @include maxDevice(767px){
            border: none !important;
            padding: 0 !important;
    
            &-icon{
                width: 31px;
                height: 30px;
                background-image: url('../../assets//images/list.svg') !important;            
            }
    
            &:not(.collapsed){
                filter: invert(1);
    
                .navbar-toggler-icon{
                    background-image: url('../../assets//images/close.svg') !important;
                }
            }
        }
    }

    &-collapse{
        @include maxDevice(767px){
            @include transition(all 0.35s ease);
            background-color: $dark;
            position: absolute;
            @include flexbox();
            height: 100vh;
            color: $white;
            z-index: -1;
            width: 100%;
            top: -100vh;
            left: 0;
    
            &.show{
                top: 0;
            }
    
            & > div{
                margin: auto !important;
            }
    
            a{
                color: $white !important;
                text-align: center;
                margin: 0 0 25px;
                font-size: 22px;
            }
        }
    }
}