.footer{
    background-color: $dark;
    padding: 32px 0 20px;
    font-size: 14px;
    color: $white;

    @include maxDevice(767px){
        padding: 42px 0 36px;
    }

    a{
        color: inherit;
        font-family: 'IBM Plex Mono', monospace;

        &.btn-round{
            padding: 11px !important;
        }
    }

    &-logo{
        text-transform: uppercase;
        letter-spacing: 0.27em;
        font-size: 32px;

        @include maxDevice(767px){
            font-size: 30px;
        }
    }

    ul{
        @include maxDevice(575px){
            @include justify-content(space-around !important)
        }

        a{
            text-transform: uppercase;
        }
    }

    li{
        margin: 0 15px 0;

        @include maxDevice(575px){
            margin: 0;
        }
    }

    p{
        font-size: 13px;
        font-weight: 300;
        letter-spacing: 2px;

        @include maxDevice(575px){
            font-size: 13px;
            letter-spacing: 1px;
        }
    }
}